<template>
  <div class="box">
    <div class="has-margin-bottom">
      <label class="checkbox has-text-weight-bold">
        <input
          type="checkbox"
          @change="updateYieldPlayground"
          v-model="yieldSettingsPlayground.ApplyGroupSize"
        />
        Group size
      </label>

      <span class="has-margin-left">
        <a @click="setShowInfo">
          <span class="icon is-small">
            <font-awesome-icon :icon="['fas', 'info-circle']" />
          </span>
        </a>
      </span>
    </div>

    <div v-if="showInfo">
      <article class="message is-info is-small">
        <div class="message-body"></div>
      </article>
    </div>

    <div v-if="yieldSettingsPlayground.ApplyGroupSize && mShowBox && !showInfo">
      <table class="table is-fullwidth is-narrow is-striped">
        <tbody>
          <tr v-if="sortedGroupSizes.length === 0">
            <td colspan="5">
              <article class="message is-info is-small has-margin-bottom">
                <div class="message-body">No group sizes defined</div>
              </article>
            </td>
          </tr>
          <template v-if="sortedGroupSizes.length > 0">
            <tr v-for="(groupSize, index) in sortedGroupSizes" :key="index">
              <td width="40">
                <span class="is-size-7"
                  >{{ getPreviousGroupSize(index) }} -
                </span>
              </td>
              <td width="50">
                <input
                  type="text"
                  maxSize="4"
                  @change="updateYieldPlayground"
                  v-model="groupSize.MaxSize"
                  class="input is-small"
                />
              </td>
              <td width="100">
                <div class="field has-addons">
                  <p class="control">
                    <input
                      type="text"
                      @change="updateYieldPlayground"
                      v-model.number="groupSize.Percentage"
                      class="input is-small"
                    />
                  </p>
                  <p class="control">
                    <a class="button is-small is-static">%</a>
                  </p>
                </div>
              </td>
              <td>
                <span v-if="groupSize.Percentage < 0" class="is-size-7"
                  >Discount</span
                >
                <span v-if="groupSize.Percentage > 0" class="is-size-7"
                  >Surcharge</span
                >
              </td>
              <td>
                <a @click="removeGroupSize(index)">
                  <span class="icon has-text-danger">
                    <font-awesome-icon :icon="['fas', 'times']" />
                  </span>
                </a>
              </td>
            </tr>
          </template>
        </tbody>

        <tfoot>
          <tr>
            <td width="40">
              <span class="is-size-7"
                >{{
                  getPreviousGroupSize(
                    yieldSettingsPlayground.GroupSizes.length
                  )
                }}
                -
              </span>
            </td>
            <td width="50">
              <input
                type="text"
                maxSize="4"
                v-model.number="newGroupSize.MaxSize"
                class="input is-small"
              />
            </td>
            <td width="100">
              <div class="field has-addons">
                <p class="control">
                  <input
                    type="text"
                    v-on:keyup.13="addGroupSize"
                    v-model.number="newGroupSize.Percentage"
                    class="input is-small"
                  />
                </p>
                <p class="control">
                  <a class="button is-small is-static">%</a>
                </p>
              </div>
            </td>
            <td>
              <span class="is-size-7">{{ actionLabel }}</span>
            </td>
            <td width="40">
              <a @click="addGroupSize">
                <span class="icon has-text-success">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </span>
              </a>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>

    <div
      v-if="yieldSettingsPlayground.ApplyGroupSize"
      class="has-text-centered has-margin-top"
    >
      <a @click="mShowBox = !mShowBox" class="button is-small is-rounded">
        <span v-if="mShowBox" class="icon is-small">
          <font-awesome-icon :icon="['fas', 'chevron-up']" />
        </span>
        <span v-if="!mShowBox" class="icon is-small">
          <font-awesome-icon :icon="['fas', 'chevron-down']" />
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  props: {
    showBox: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      mShowBox: true,
      newGroupSize: null,
      showInfo: false
    }
  },

  computed: {
    ...mapState('yieldStore', ['yieldSettingsPlayground']),

    sortedGroupSizes() {
      let self = this
      let groupsizes = []
      if (
        self.yieldSettingsPlayground &&
        self.yieldSettingsPlayground.GroupSizes.length > 0
      ) {
        groupsizes = self.yieldSettingsPlayground.GroupSizes.sort(function(
          a,
          b
        ) {
          return a.MaxSize - b.MaxSize
        })
      }
      return groupsizes
    },

    actionLabel() {
      let label = ''

      if (this.newGroupSize && this.newGroupSize.Percentage) {
        if (this.newGroupSize.Percentage < 0) {
          label = 'discount'
        }
        if (this.newGroupSize.Percentage > 0) {
          label = 'surcharge'
        }
      }

      return label
    }
  },

  watch: {
    showBox: {
      immediate: true,
      handler: function(newVal, oldVal) {
        this.mShowBox = newVal
      }
    }
  },

  created() {
    this.newGroupSize = this.setNewGroupSizeDefaults()
  },

  methods: {
    ...mapMutations('yieldStore', ['setYieldSettingsPlayground']),

    setShowInfo() {
      this.showInfo = !this.showInfo
      if (this.showInfo) {
        this.mShowBox = true
      }
    },

    getPreviousGroupSize(index) {
      let self = this
      let previousGroupSize = null
      let size = 1

      if (index > 0) {
        previousGroupSize = self.yieldSettingsPlayground.GroupSizes[index - 1]
        if (previousGroupSize) {
          size = Number(previousGroupSize.MaxSize) + 1
        }
      }
      return size
    },

    setNewGroupSizeDefaults() {
      let self = this

      let existingGroupSizes = self.yieldSettingsPlayground.GroupSizes.length
      let maxSize = 1
      if (existingGroupSizes > 0) {
        let previousMaxSize = self.getPreviousGroupSize(existingGroupSizes)
        maxSize = Number(previousMaxSize) + 5 - 1
      }

      return {
        ChannelId: self.yieldSettingsPlayground.ChannelId,
        LocationId: self.yieldSettingsPlayground.LocationId,
        MaxSize: maxSize,
        Percentage: 0
      }
    },

    addGroupSize() {
      let self = this

      self.yieldSettingsPlayground.GroupSizes.push(self.newGroupSize)
      self.newGroupSize = self.setNewGroupSizeDefaults()
      self.updateYieldPlayground()
    },

    removeGroupSize(index) {
      let self = this

      self.yieldSettingsPlayground.GroupSizes.splice(index, 1)
      self.updateYieldPlayground()
    },

    updateYieldPlayground() {
      this.setYieldSettingsPlayground(this.yieldSettingsPlayground)
      this.$emit('yieldSettingsUpdated')
    }
  }
}
</script>
